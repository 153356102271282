@import "./_vars";

.pageWrapper {
    display: flex;
    height: 100vh;
    overflow: auto;
}

.sidebar {
    background-color: $tote-secondary;
    color: $tote-secondary-text;

    .retailersList {
        color: $tote-secondary;
    }

    .divider {
        width: 100%;
    }

    .navContainer {
        width: 100%;
        align-items: flex-start;
    }

    .brandLogo {
        width: 150px;
        height: 150px;
        display: block;
        background-image: url('/assets/logo-yellow.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .navigation {
        width: 100%;
    }

    .sidebarItem {
        color: inherit !important;
        padding: 15px;
        display: block;
        font-weight: 600;
        text-align: center;

        &:hover {
            text-decoration: none;
            background-color: rgba(#000, 0.1);
        }
    }

    .sidebarItemActive {
        background-color: rgba(#000, 0.1);
    }
}

.toggle {
    align-self: end;
    padding: 8px;

    &:hover {
        cursor: pointer;
    }

    &_expanded {
        align-self: center;
    }
}

.hidden {
    display: none !important;
}

@media (max-width: 992px) {
    .pageWrapper {
        flex-direction: column;

        .toggle {
            visibility: hidden;
        }

        .sidebar {
            width: auto;
            flex-direction: row;
            justify-content: space-between;

            .navContainer {
                margin-top: 5px;
            }
        }
    }
}



@media (min-width: 992px) {
    .sidebar {
        min-width: 325px;
        flex-direction: column !important;
        padding: 0 !important;

        &_expanded {
            min-width: 50px;
        }

        .brandLogo {
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .navigation {
            flex-direction: column !important;
        }
    }
}


.pageContent {
    flex-grow: 2;
    overflow-y: auto;
    padding: 25px;
    height: 100vh;
}
// Colors
$tote-primary: #FDF5E9;
$tote-secondary: #281A1A;
$tote-secondary-text: #FDF5E9;
$virtual-event: #5C71A9;
$in-person-event: #458A71;
$employee-event: #FED861;
$background: white;

$theme-colors: (
    "primary": $tote-secondary,
    "dark": $tote-secondary
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

@import './_vars';
@import '~flatpickr/dist/themes/airbnb';

.flatpickr-calendar {
    font-family: 'Noto Sans', Helvetica, Arial, sans-serif;
}

.flatpickr-current-month,
.flatpickr-current-month input.cur-year,
.flatpickr-current-month .flatpickr-monthDropdown-months {
    font-weight: 400;
}

.flatpickr-day.selected, .flatpickr-day.selected:hover {
    background-color: $tote-primary;
    border-color: $tote-primary;
}

.flatpickr-day.today:hover, .flatpickr-day.today:not(.selected):hover {
    background-color: $tote-primary;
    border-top-color: $tote-primary;
    border-left-color: $tote-primary;
    border-right-color: $tote-primary;
}

.flatpickr-day.nextMonthDay:not(.flatpickr-disabled) {
    color: #404848;
}

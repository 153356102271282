@import './_vars';

input.form-control, select.form-control, a.form-control {
    font-weight: 600;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    transition: border-color .2s;
    margin: 5px 0;
    display: block;
    width: 100%;

    &::placeholder {
        color: #888;
    }
    &:focus, &:hover {
        border-color: $tote-primary;
    }
}

textarea.form-control {
    resize: none;
}

select.form-control {
    cursor: pointer;
}

.input-group > .form-control {
    margin: 0;
}

.input-group-text {
    font-size: 0.9rem;
}

.flatpickrInputContainer {
    position: relative;
    margin: 5px 0;
    width: 100%;

    &::after {
        position: absolute;
        display: block;
        right: 6px;
        top: calc(50% - 10px);
        width: 18px;
        height: 18px;
        content: ' ';
        background: url('/assets/calendar.svg') center center no-repeat;
        background-size: 16px;
        pointer-events: none;
    }

    .form-control {
        cursor: pointer;
        user-select: none;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;

        &.flatpickr-input {
            background: #fff;
        }
    }
}

.btn {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    padding: 15px 35px;
    border: 0;
    cursor: pointer;
    text-decoration: none;

    &.btn-outline-danger,
    &.btn-outline-warning,
    &.btn-outline-primary,
    &.btn-outline-secondary,
    &.btn-outline-success {
        border-width: 1px;
        border-style: solid;
    }
    &.btn-primary {
        background-color: $tote-secondary;
        color: $tote-secondary-text;
    }
    &.btn-sm {
        padding: 7px 18px;
    }

    &:disabled {
        opacity: .7;
    }
}

.radioToggleGroup {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 0 0;

    &.radioToggleGroupCentered {
        justify-content: center;
    }

    input[type=radio], input[type=checkbox] {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;

        &:checked + label {
            border-color: $tote-primary;
            background-color: $tote-primary;
            font-weight: 600;
            color: #333;
        }
    }

    label {
        color: #777;
        font-size: 0.9rem;
        text-align: center;
        padding: 8px 16px;
        margin: 0 8px 5px 0;
        border: 1px solid #ccc;
        transition: border-color .1s ease-in-out, background-color .2s ease-in-out, color .2s ease-in-out;
        background-color: #fff;

        &:hover {
            cursor: pointer;
            border-color: $tote-primary;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.error-message{
    margin-top: 4px;
    color: red;
}

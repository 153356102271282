.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    z-index: 1000;

    &.success {
        background-color: green;
    }

    &.error {
        background-color: red;
    }

    &.warning {
        background-color: yellow;
    }

    @media (max-width: 768px) {
        transform: translate(-50%);
    }
}

.nonAppPage {
    transform: translate(-50%);
}
